/* RoadShow.css */

/* Change the arrow color for the entire carousel */
.slick-carousel-1 .slick-prev:before,
.slick-carousel-1 .slick-next:before {
  color: rgb(0, 0, 0) !important; /* Change this to your desired arrow color */
  font-size: 20px;
  opacity: 100;
}

/* Change the arrow color on hover */
.slick-carousel-1 .slick-prev:hover:before,
.slick-carousel-1 .slick-next:hover:before {
  color: rgb(87, 87, 87) !important; /* Change this to your desired arrow color on hover */
  background-color: rgba(0, 0, 0, 0);
  
}

.slick-dots {
  text-align: left !important;
}

.image-container {
  height: 200px !important;
}