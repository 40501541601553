@tailwind base;
@tailwind components;
@tailwind utilities;

/* Example CSS animation */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInTop {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
/* Apply the animation to the logo container */
.animate-slideInLeft {
  animation: slideInLeft 0.8s ease-out forwards; /* Adjust duration and timing function as needed */
}

.animate-slideInLeft1 {
  animation: slideInLeft 1.2s ease-out forwards; /* Adjust duration and timing function as needed */
}

.animate-slideInLeft2 {
  animation: slideInLeft 1.6s ease-out forwards; /* Adjust duration and timing function as needed */
}

.animate-slideInRight {
  animation: slideInRight 0.8s ease-out forwards; /* Adjust duration and timing function as needed */
}

.animate-slideInRight1 {
  animation: slideInRight 0.8s ease-out forwards; /* Adjust duration and timing function as needed */
}

.animate-slideInRight2 {
  animation: slideInRight 0.8s ease-out forwards; /* Adjust duration and timing function as needed */
}

.animate-slideInTop {
  animation: slideInTop 0.8s ease-out forwards; /* Adjust duration and timing function as needed */
}

.animate-slideInTop1 {
  animation: slideInTop 1.2s ease-out forwards; /* Adjust duration and timing function as needed */
}

.animate-slideInTop2 {
  animation: slideInTop 1.6s ease-out forwards; /* Adjust duration and timing function as needed */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-css {
  color: #FFFFFF;
  font-family: "Figtree", Sans-serif;
  font-size: 68px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 91px;
  letter-spacing: 3px;
}

@font-face {
  font-family: 'Figtree';
  src: url('./asset/Figtree.ttf') format('truetype');
  /* Add additional font weights and styles if available */
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}